<template>
    <v-card hover>   
        <v-card-title style="background-color:#4E87DD; color:#FFFFFF; height:70px;">   
            <v-row>
                <v-col class="pt-1 pl-4 text-h5 font-weight-bold">{{curBill.bill_month | dateMonth}} 청구요금 상세</v-col>
            </v-row>        
        </v-card-title>

        <v-card-text class="ma-0 pb-0 pl-7 pr-7 pt-3">
            <v-card outlined>
                <v-simple-table>
                    <template v-slot:default>
                    <thead>
                        <tr style="background-color:#F4F6F8; height:78px; color:#333333; font-weight:600;">
                            <th class="text-left text-h6 pl-6">
                                콜리
                            </th>
                            <th class="text-left text-h6">
                                이용기간
                            </th>
                            <th class="text-left text-h6">
                                청구금액 (원)
                            </th>
                        </tr>
                    </thead>
                    <tbody style="color:#555555;">
                        <template v-for="(detailItem, detailIdx) in billDetailList">
                        <tr style="height:68px;" :key="'bill_' + detailIdx">
                            <td class="pl-6" style="font-size:20px;">{{detailItem.user_name}}</td>
                            <td class="text-left" style="font-size:20px;">{{detailItem.svc_start_date | dateMin}} ~ {{detailItem.svc_end_date | dateMin}}</td>
                            <td class="text-left" style="font-size:20px;">{{detailItem.billing_amount | number}}</td>
                        </tr>
                        </template>
                        <tr style="height:69px;" v-if="!billDetailList || billDetailList.length == 0">
                            <td class="text-center" style="font-size:20px;" colspan="3">조회된 결과가 없습니다</td>
                        </tr>
                        <tr style="height:68px; background-color:#F4F4F4;" v-if="billDetailList && billDetailList.length > 0 && curBill.mng_discount_amount != 0">
                            <td colspan="2" class="pl-6" style="font-size:20px;">{{ curBill.mng_discount_desc }}</td>
                            <td class="text-left" style="font-size:20px;">{{ Math.abs(curBill.mng_discount_amount) | number }}</td>
                        </tr>

                        <tr style="height:68px; background-color:#F4F4F4;" v-if="billDetailList && billDetailList.length > 0">
                            <td class="pl-6" style="font-size:20px;">&nbsp;</td>
                            <td class="text-left" style="font-size:20px;">&nbsp;</td>
                            <td class="text-left" style="font-size:20px;">총합 : {{sumOfBillingAmount-curBill.mng_discount_amount | number}}</td>
                        </tr>

                    </tbody>
                    </template>  
                </v-simple-table>   
            </v-card>
        </v-card-text>        
        <v-card-actions class="ma-0 pa-0">
            <v-row no-gutters>
                <v-col cols="12" class="pr-5 pb-4 pt-3 text-right">
                    <v-btn class="ma-2" color="primary" dark width="130px" v-on:click="closePopup()"><span style="font-color: #555555; font-size: 1.4em;">확인</span></v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
    
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data: () => ({
        formData: {
            admin_user_password: '',
        },
        formStatus: { 
            admin_user_password: {
                err: false,
                err_msg: '',
            },
        }
    }),
    computed: {
        ...mapGetters({
            curBill: 'pay/getCurBill',
            billDetailList: 'pay/getBillDetailList'
        }),  
        sumOfBillingAmount () { 
            var ret = 0
            if(this.billDetailList) { 
                this.billDetailList.forEach(item => { 
                    ret += item.billing_amount
                })
            }
            return ret
        }
    },    
    mounted() {
    },
    methods: {
        closePopup() { 
            this.$emit('cancel')
        }
    }
}
</script>
